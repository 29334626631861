import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const IndexPage = () => (
  <Layout>
    <SEO title="Startup Station"/>
    <Hero>
      <h1>We build ventures and <span/>partner with early-stage startups</h1>
      <p>
        Success is a journey, not a destination. <br/>
        The doing is often more important than the outcome <span className="author">&mdash; Arthur Ashe</span>
      </p>
    </Hero>
    <Contact>
      <h5>Contact</h5>

      <a href="mailto:hello@startupstation.co" title="Get int touch">
        hello@startupstation.co
      </a>
      <br/>

      <div className="row">
        <div className="col-md-4">
          <p>
            Palácio Sotto Mayor - Av. Fontes Pereira de Melo, 16<br />
            1050-121, Lisbon<br />
            <strong>Portugal</strong>
          </p>
        </div>
      </div>

    </Contact>
  </Layout>
)


const Hero = styled.section`
    margin: 20px 0;
    
    h1 {
        font-weight: bold;
        font-size: 2.5em;
        line-height: 1.1;
        width: 100%;
        margin-bottom: 20px;
    }
    p {
        color: #656565;
        font-size: 1.3em;
        line-height: 1.35;
        
        .author {
            color: #CCC;
        }
    }
    
    @media (min-width: 778px) {
        width: 620px;
        margin: 100px 0;
        h1 {
            font-size: 2.8em;
        }
        h1 span {
            display: block;
        }
        p {
            font-size: 1.35em;
        }
    }
`

const Contact = styled.section`
    margin-top: 50px;
    h5 {
        font-weight: bold;
        font-size: 1.25em;
        margin-bottom: 20px;
    }
    a {
        font-size: 1.2em;
    }
    p {
        margin: 20px 0;
        line-height: 1.3
    }
`

export default IndexPage
